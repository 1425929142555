import SalesforceCommonApi from '../apis/SalesforceCommonApi';
import Utils from '../utils/Utils';
import SalesforceService from './SalesforceService';

let parameters = undefined;

const ApplicationService = {
	getParameters: () => {
		if (!parameters) {
			parameters = Utils.toParametersUsingQueryString(window.location.search);
		}
		return parameters;
	},
	getIconPath: () => {
		return '    ';
	},
	getNavigationUrl: () => {
		let url = '';
		let component = '';
		let recordId = '';
		if (window.component) {
			component = window.component;
		}
		if (window.recordId) {
			recordId = window.recordId;
		}

		let params = new URLSearchParams(document.location.search);
		let componentParam = params.get('component');
		let recordIdParam = params.get('recordId');

		if (componentParam) {
			component = componentParam;
		}
		if (recordIdParam) {
			recordId = recordIdParam;
		}

		if (component) url += '/' + component;
		if (recordId) url += '/' + recordId;

		url = Utils.addAdditionalUri(url, component, SalesforceService.getParams());

		return url;
	},
	fireGenericEvent: (params, callback, ref) => {
		if (!params) params = {};
		// eventName, eventParams, sourceComponent, eventId

		try {
			window.parent.postMessage({
				eventName: params.eventName,
				eventId: '',
				data: params,
			});
		} catch (e) {
			//console.log('error while redirecting to project:',e);
		}
	},
	getFeatureConfigurations : ()=>{
		return new Promise((resolve, reject)=>{
			SalesforceCommonApi.query({
				query : `SELECT Id, DeveloperName, MasterLabel, NamespacePrefix, QualifiedApiName, fwave__Value__c, fwave__IsDisabled__c FROM fwave__FeatureConfiguration__mdt WHERE fwave__IsDisabled__c = false AND NamespacePrefix = 'fwave'`
			}).then((featureConfigs)=>{
				let labels = {};
				for(let featureConfig of featureConfigs){
					labels[featureConfig.QualifiedApiName] = featureConfig['fwave__Value__c'];
				}
				resolve(labels);
			})
		});
	}
};

export default ApplicationService;
