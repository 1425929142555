import React, { useEffect } from 'react';
import { Outlet, Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import '@salesforce/canvas-js-sdk';

import './App.css';
import './AppStyle.scss';

import { withLazySuspense } from './components/common/SuspenseUtils';
import Utils from './utils/Utils';

import ApplicationService from './services/ApplicationService';
import SalesforceService from './services/SalesforceService';

const AccountInsightsStandaloneContainerCmp = withLazySuspense(
	() => import('./components/account/AccountInsightsStandaloneContainerCmp'),
);
const AdminSettingsFilesTabCmp = withLazySuspense(
	() => import('./components/admin/AdminSettingsFilesTabCmp'),
);
const AdminSettingsOtherCmp = withLazySuspense(
	() => import('./components/admin/AdminSettingsOtherCmp'),
);
const AdminSettingsPersonaInsightCmp = withLazySuspense(
	() => import('./components/admin/AdminSettingsPersonaInsightCmp'),
);
const AdminSettingsSalesPlaySummaryCmp = withLazySuspense(
	() => import('./components/admin/salesplay/AdminSettingsSalesPlaySummaryCmp'),
);
const AdminSettingsScheduleCmp = withLazySuspense(
	() => import('./components/admin/AdminSettingsScheduleCmp'),
);
const AdminSettingsScheduleExecuteCmp = withLazySuspense(
	() => import('./components/admin/AdminSettingsScheduleExecuteCmp'),
);
const AdminSettingsScheduleSObjectWrapperCmp = withLazySuspense(
	() => import('./components/admin/AdminSettingsScheduleSObjectWrapperCmp'),
);
const AdminSettingsSetupCmp = withLazySuspense(
	() => import('./components/admin/AdminSettingsSetupCmp'),
);
const AdminSettingsSummaryPreviewCmp = withLazySuspense(
	() => import('./components/admin/AdminSettingsSummaryPreviewCmp'),
);
const AdminSettingsMainCmp = withLazySuspense(
	() => import('./components/admin/AdminSettingsMainCmp'),
);
const CompanyProfileCmpNew = withLazySuspense(
	() => import('./components/admin/CompanyProfileCmpNew'),
);
const DashboardCmp = withLazySuspense(() => import('./components/DashboardCmp'));
const FilesOtherSourcesCmp = withLazySuspense(
	() => import('./components/admin/files/FilesOtherSourcesCmp'),
);
const GPTPlaygroundCmp = withLazySuspense(() => import('./components/GPTPlaygroundCmp'));
const OpportunityViewCmp = withLazySuspense(
	() => import('./components/opportunity/OpportunityViewCmp'),
);
const RequirementStandaloneCmp = withLazySuspense(
	() => import('./components/RequirementStandaloneCmp'),
);
const SalesPlayAdminHome = withLazySuspense(
	() => import('./components/admin/salesplay/SalesPlayAdminHome'),
);
const SearchCmp = withLazySuspense(() => import('./components/SearchCmp'));
const SearchResult = withLazySuspense(() => import('./components/search/SearchResult'));
//const queryClient = new QueryClient()

function App() {
	const navigate = useNavigate();
	let _url = ApplicationService.getNavigationUrl();
	if (Utils.isLocalhost() && _url !== '') navigate(_url);
	//const [isAuth, setIsAuth] = useState(false);

	// const getFeatureConfigurationsAsync =  async()=>{
	// 	let data = await ApplicationService.getFeatureConfigurations();
	// 	return data;
	// }
	// const { data: featureConfigurations, isLoading: isMetadataLoading } = useQuery({
	// 	enabled : true,
	// 	queryKey: [QKEY_FEATURE_CONFIG],
	// 	queryFn: async () => {
	// 		return await getFeatureConfigurationsAsync();
	// 		//return await getMyOppties("005RL00000AtA96YAF")
	// 		},
	// });

	useEffect(() => {
		global.Sfdc.canvas.client.refreshSignedRequest(function (data) {
			const signedRequest = data.payload.response;
			const part = signedRequest.split('.')[1];
			const obj = JSON.parse(global.Sfdc.canvas.decode(part));
			SalesforceService.setSalesforceInfo({
				sessionId: obj.client.oauthToken,
				salesforceEndPoint: obj.client.instanceUrl,
				instanceId: obj.client.instanceId,
				client: obj.client,
				organizationId: obj.context.organization.organizationId,
				params: obj.context.environment.parameters,
			});
			//setIsAuth(true);
			ApplicationService.getFeatureConfigurations().then((_labels) => {
				SalesforceService.setLabels(_labels);
			});

			const _url = ApplicationService.getNavigationUrl();
			if (_url !== '') navigate(_url);
		});
		if (Utils.isLocalhost()) {
			ApplicationService.getFeatureConfigurations().then((_labels) => {
				SalesforceService.setLabels(_labels);
			});
		}
	}, []);

	return (
		<Routes>
			<Route path="/" element={<Outlet />}>
				<Route path="/dashboard/:currentUserId">
					<Route path="" element={<DashboardCmp />} />
					<Route path=":recordType/:primaryRecordId" element={<DashboardCmp />} />
				</Route>
				<Route path="/account/:accountId" element={<OpportunityViewCmp />} />
				<Route path="/opportunity/:opportunityId" element={<OpportunityViewCmp />} />
				<Route path="/admin" element={<AdminSettingsMainCmp />}>
					<Route exact path="/admin" element={<Navigate to="companyprofile" />} />
					<Route path="companyprofile" element={<CompanyProfileCmpNew />} />
					<Route path="salesplay" element={<SalesPlayAdminHome />}></Route>
					<Route
						path="salesplay/:salesPlayId/:relatedList"
						element={<SalesPlayAdminHome />}
					/>
					<Route path="files" element={<AdminSettingsFilesTabCmp />} />
				</Route>
				<Route path="/admin/setup" element={<AdminSettingsSetupCmp />}>
					<Route exact path="/admin/setup/" element={<Navigate to="scheduleInsight" />} />
					<Route path="scheduleInsight" element={<AdminSettingsScheduleCmp />}>
						<Route
							exact
							path="/admin/setup/scheduleInsight/"
							element={<Navigate to="sobject" />}
						/>
						<Route
							path="sobject"
							element={<AdminSettingsScheduleSObjectWrapperCmp />}
						/>
						<Route path="execute" element={<AdminSettingsScheduleExecuteCmp />} />
						<Route path="preview" element={<AdminSettingsSummaryPreviewCmp />} />
						<Route
							path="leadpreview"
							element={<AdminSettingsSummaryPreviewCmp sObjectType="Lead" />}
						/>
					</Route>
					<Route path="personaInsight" element={<AdminSettingsPersonaInsightCmp />} />
					<Route
						path="leadpersonaInsight"
						element={<AdminSettingsPersonaInsightCmp sObjectType="Lead" />}
					/>
					<Route path="salesPlaySummary" element={<AdminSettingsSalesPlaySummaryCmp />} />
					<Route path="otherSettings" element={<AdminSettingsOtherCmp />} />
				</Route>
				<Route path="/filesOtherSources" element={<FilesOtherSourcesCmp />} />
				<Route path="/requirement/:opptyId" element={<RequirementStandaloneCmp />} />
				<Route
					path="/accountSummary/:accountId"
					element={<AccountInsightsStandaloneContainerCmp />}
				/>
				<Route path="/selectFile" element={<AdminSettingsMainCmp />} errorElement />
				<Route path="/selectFile/:defaultTab" element={<AdminSettingsMainCmp />} />
				<Route path="/playground" element={<GPTPlaygroundCmp />} />
				<Route path="/search">
					<Route path="" element={<SearchCmp />} />
					<Route path=":featureId" element={<SearchResult />} />
				</Route>
			</Route>
		</Routes>
	);
}
export default App;
