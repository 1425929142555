import SalesforceService from '../services/SalesforceService';

const HttpUtils = {};
HttpUtils.invoke = async (_parameters) => {
	// console.log({_parameters});
	let method = _parameters.method || 'GET';
	let endPoint = _parameters.url;
	let params = _parameters.parameters;
	let headers = _parameters.headers || {};
	let token = SalesforceService.getSessionId();
	let isDoc = endPoint && endPoint.indexOf('doctohtml') !== -1;
	let isText = endPoint && endPoint.indexOf('gethtml') !== -1;
	if (isDoc) {
		token = 'Bearer ' + token;
	}
	const defaultHeaders = {
		token: token,
		'instance-url': SalesforceService.getInstanceUrl(),
		'org-id': SalesforceService.getSfOrgId(),
		'Access-Control-Allow-Origin': '*',
	};
	if (!_parameters.body && !isText) {
		defaultHeaders['Content-Type'] = 'application/json';
	}

	let bodyParam =
		_parameters.body &&
		(typeof _parameters.body === 'string'
			? _parameters.body
			: JSON.stringify(_parameters.body));
	if (bodyParam === '{}') bodyParam = undefined;
	const body =
		params && Object.keys(params).length > 0
			? JSON.stringify(params || {})
			: bodyParam || undefined;

	const requestOptions = {
		method: method,
		headers: {
			...defaultHeaders,
			...(headers || {}),
		},
		body,
		redirect: 'follow',
	};

	return fetch(endPoint, requestOptions).then((response) => {
		let result = isDoc ? response.arrayBuffer() : isText ? response.text() : response.json();
		// try{
		//     result = response.json()
		// }catch(e){
		//     result = response.arrayBuffer()
		// }
		return result;
	});
};

HttpUtils.post = (_parameters) => {
	let params = _parameters || {};
	params.method = 'POST';
	return HttpUtils.invoke(params);
};

export default HttpUtils;
