export const COMPANY_NAME = 'Amplify10';

// app wide constants
export const WORD_IMPORT_WELCOME_MODAL_KEY = 'isWordImportWelcomeShown';
export const NAMESPACE = 'fwave';
export const NAMESPACE_PREFIX = NAMESPACE && NAMESPACE !== '' ? NAMESPACE + '__' : '';
export const NAMESPACE_CLASS = NAMESPACE && NAMESPACE !== '' ? NAMESPACE + '.' : '';
export const FILETYPE_REQUIREMENT = 'requirement';
export const RFP_PRODUCT_TAG = 'rfp';
export const INTERNAL_PRODUCT_TAG = 'internal';
export const EXTERNAL_PRODUCT_TAG = 'external';
export const RECENT_SEARCH = 'recentSearch';
export const RECENT_SEARCH_LIMIT = 5;
export const QUESTIONNAIRE_SUMMARY = 'qSummary';
export const QUESTIONNAIRE_SUMMARY_FAVORITES = 'qSummaryFavorites';
export const CLUSTER_QUERY_RESULT = 'clusterQuery';
export const MY_OPPTY_FILTER = 'myOpptyFilter';
export const ALL_OPPTY_FILTER = 'allOpptyFilter';
export const RFP_ONLY_MAP = 'rfpOnlyMap';
export const NEWS_MAP = 'newsMap';
export const ACCOUNT_SUMMARY_MAP = 'acctSummariesMap';
export const DEFAULT_OPP_OBJ = 'Opportunity';
export const DEFAULT_AMOUNT_FIELD = 'Amount';
export const DEFAULT_EXPECTED_REVENUE_FIELD = 'ExpectedRevenue';
export const DEFAULT_STAGE_NAME_FIELD = 'StageName';
export const DEFAULT_CLOSE_DATE_FIELD = 'CloseDate';
export const OBJECT_TYPE_CONTENTVERSION = 'ContentVersion';
export const OBJECT_TYPE_USER = 'User';
export const OBJECT_TYPE_TAG = 'Tag__c';
export const OBJECT_TYPE_SOLUTION = 'Solution__c';
export const OBJECT_TYPE_REQUIREMENT = 'Requirement__c';
export const OBJECT_TYPE_GPT_SUMMARY = NAMESPACE_PREFIX + 'GPTSummary__c';
export const OBJECT_TYPE_File = 'File__c';
export const OBJECT_TYPE_SOBJECT_EXPORT = NAMESPACE_PREFIX + 'SObjectExport__c';
export const SOBJECT_EXPORT_FILENAME = 'Amplify10 Export';
// app wide admin constants
export const INTEGRATION_GOOGLE_DRIVE = 'Google Drive';
export const INTEGRATION_MINDTICKLE = 'Mindtickle';
export const INTEGRATION_SHARE_POINT = 'OneDrive / SharePoint';
export const SHARE_POINT_FIELDS = [
	{ fieldName: 'Title', fieldType: 'string' },
	{ fieldName: 'App Domain', fieldType: 'string' },
	{ fieldName: 'Client Id', fieldType: 'string' },
	{ fieldName: 'Client Secret', fieldType: 'password' },
	{ fieldName: 'Redirect URI', fieldType: 'string' },
];
export const FILE_TYPE_USER_GUIDES = 'User Guides';
export const FILE_TYPE_SALESFORCE_FILES = 'Salesforce Files';
export const FILE_SCAN_BYPAGE = 'page';
export const FILE_SCAN_BYSECTION = 'section';
export const FILE_TYPE_SUPPORTING_DOCUMENTS = 'Supporting Documents';
export const SUPPORTED_UPLOAD_DOCUMENT_MIME_TYPE = [
	//'application/msword',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	'application/vnd.ms-powerpoint',
	'application/vnd.openxmlformats-officedocument.presentationml.presentation',
	//'application/vnd.ms-excel',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	'application/pdf',
];
export const ACCOUNT_SUMMARY_CHATGPT_PROMPT =
	'summarize this sales account for me with the emphasis on the current health of the account and its open opportunities';
export const FILE_SOURCE_SALESFORCE = 'salesforce';
export const FILE_SOURCE_SHAREPOINT = 'sharepoint';
export const FILE_SOURCE_MIND_TICKLE = 'Mindtickle';
export const FILE_SOURCE_BOX = 'Box';
export const FILE_SOURCE_GOOGLE_DRIVE = 'GoogleDrive';
export const FILE_SOURCE_ONE_DRIVE = 'OneDrive';
export const FILE_SOURCE_LINK = 'Url';
export const FILE_SOURCE_WEBSITE = 'Website';
export const FILE_SOURCE_CONFLUENCE = 'Confluence';
export const FILE_SOURCE_MICROSOFT = 'Microsoft';

// used for internal search result
export const INTERNAL_SEARCH_STRONG_TH = 50;
export const INTERNAL_SEARCH_MEDIUM_TH = 30;

// used for cachcing expiration
export const ACCOUNT_SUMMARY_CACHE_DAYS = 5;
export const CUSTOM_SETTING_CACHE_DAYS = 1;
export const LATEST_NEWS_CACHE_DAYS = 1;

// turn on and off any placeholder / non-functional items
export const ENABLE_PLACEHOLDER = false;

// used for chatgpt
export const DEFAULT_MESSAGE_NO_ANSWER =
	'Our Sales Assistant is unable to get you the right answer. You might want to refer to the internal and internet search results.';

//Sales Play Section Constant
export const SALESPLAY_SECTION_SALESPLAY = 'Sales Play';
export const SALESPLAY_SECTION_SALESPLAY_PLURAL = 'Sales Plays';
export const SALESPLAY_SECTION_SUMMARY = 'Sales Play Summary';
export const SALESPLAY_SECTION_CRITICALMESSAGING = 'Critical Messaging';
export const SALESPLAY_SECTION_CRITICALMESSAGING_PLURAL = 'Critical Messaging';
export const SALESPLAY_SECTION_DISCOVERY = 'Discovery and Industry Trends';
export const SALESPLAY_SECTION_DISCOVERY_PLURAL = 'Discovery and Industry Trends';
export const SALESPLAY_SECTION_COMPETITION = 'Competition';
export const SALESPLAY_SECTION_COMPETITION_PLURAL = 'Competitions';
export const SALESPLAY_SECTION_OBJECTION = 'Objection Handling';
export const SALESPLAY_SECTION_OBJECTION_PLURAL = 'Objection Handling';
export const SALESPLAY_SECTION_CUSTSTORY = 'Customer Stories';
export const SALESPLAY_SECTION_CUSTSTORY_PLURAL = 'Customer Stories';
export const SALESPLAY_SECTION_CALLDECK = 'First Call Deck';
export const SALESPLAY_SECTION_CALLDECK_PLURAL = 'First Call Deck';
export const SALESPLAY_SECTION_DEMO = 'Relevant Demos';
export const SALESPLAY_SECTION_DEMO_PLURAL = 'Relevant Demos';
export const SALESPLAY_SECTION_DOC = 'Documents';
export const SALESPLAY_SECTION_DOC_PLURAL = 'Documents';
export const SALESPLAY_SECTION_INDSEPCIALIST = 'Industry Specialists';
export const SALESPLAY_SECTION_INDSEPCIALIST_PLURAL = 'Industry Specialists';
export const SALESPLAY_SECTION_PERSONA_MSG = 'Persona Messaging';
export const SALESPLAY_SECTION_PERSONA_MSG_PLURAL = 'Persona Messaging';

// used for opportunity overview component
export const OPPTY_OVERVIEW_SIDEPANEL_MAP = new Map([
	['Company Profile', 'is-companyProfile'],
	['Opportunity Details', 'is-opportunityDetails'],
	[SALESPLAY_SECTION_CRITICALMESSAGING, 'sp-criticalMessaging'],
	[SALESPLAY_SECTION_OBJECTION, 'sp-objectionHandling'],
	[SALESPLAY_SECTION_CUSTSTORY, 'sp-customerStories'],
	[SALESPLAY_SECTION_CALLDECK, 'sp-firstCallDeck'],
	//TODO : DisableElevatorPitch
	// ['Elevator Pitch', 'sp-elevatorPitch'],
	[SALESPLAY_SECTION_PERSONA_MSG_PLURAL, 'sp-personaMessaging'],
	[SALESPLAY_SECTION_DISCOVERY, 'sp-discoveryIndustryTrend'],
	[SALESPLAY_SECTION_COMPETITION, 'sp-competition'],
	[SALESPLAY_SECTION_DOC, 'sp-datasheets'],
	[SALESPLAY_SECTION_DEMO, 'sp-relevantDemos'],
	[SALESPLAY_SECTION_INDSEPCIALIST, 'sp-contacts'],
]);

// used for picklist population, please update as data schema changes, eventually we would want to grab values dynamically
// this way we don't need to update codebase for different clients
export const REQ_CUSTOMER_ASK_PICKLIST_VAL = new Map([['Must Have', 'Must Have']]);
export const REQ_PRODUCT_PICKLIST_VAL = new Map([['CLM', 'CLM']]);
export const DEFAULT_OPPORTUNITY_DATE_FIELD_PICKLIST_VAL = new Map([
	['Close Date', 'CloseDate'],
	['Last Modified Date', 'LastModifiedDate'],
]);
export const DEFAULT_OPPORTUNITY_CURRENCY_FIELD_PICKLIST_VAL = new Map([
	['Expected Revenue', 'ExpectedRevenue'],
	['Amount', 'Amount'],
]);
export const OPP_HEALTH_STRONG_TH = 0.85;
export const OPP_HEALTH_MEDIUM_TH = 0.65;

// used for search
export const SEARCH_TYPE_FEATURES = 'features';
export const SEARCH_TYPE_FILES = 'files';

// this is used for constant strings for the front end
export const FEATURESTATUS_AVALIABLE = 'Available';
export const FEATURESTATUS_GAP = 'Gap';
export const PRODUCTGUIDE_LABEL = 'What We Found';
export const SIMILARFEATURE_LABEL = 'Similar Results';
export const RELATEDMATERIALS_LABEL = 'Related Materials';
export const SIMILARACCOUNT_LABEL = 'Accounts With Similar Requirements';
export const QUESTIONAIRERESPONSE_LABEL = 'Questionnaire Response';
export const TOO_LARGE_TO_STORE = '<span> too large to store</span>';
export const FETCHHTML_ID_PREFIX = 'fetchHtmlCmp';
export const NEWS_API_LIMIT = 3;
export const NEWS_API_SUCCESS = 'success';
export const COPIED_TO_CLIPBOARD_SUCCESS = 'Copied to clipboard';
export const COPIED_TO_CLIPBOARD_FAILED = 'Failed to copy to clipboard';
export const RESET_ALL_FILTERS = 'Reset All Filters';
export const ADD_NEW_FILTER = 'Add New Filter';
export const FILTER_DESCRIBE_ERROR =
	"There's an issue getting field level information from your salesforce instance, please contact your administrator for assistance.";
export const DEFAULT_NOTIFICATION_TIME = 3000;

export const DEFAULT_SEARCH_OPTIONS = {
	numOfResults: 30,
	skipRerank: false,
};
export const DEFAULT_MINI_SEARCH_OPTIONS = {
	numOfResults: 3,
	skipRerank: false,
};

export const FILTER_OPERATOR_STR = [
	{ label: 'Equal', value: '=' },
	{ label: 'Not Equal', value: '<>' },
	{ label: 'In', value: 'IN' },
	{ label: 'Not In', value: 'NOT IN' },
	{ label: 'Like', value: 'LIKE' },
	{ label: 'Not Like', value: 'NOT LIKE' },
];
export const FILTER_OPERATOR_ID = [
	{ label: 'Equal', value: '=' },
	{ label: 'Not Equal', value: '<>' },
	{ label: 'In', value: 'IN' },
	{ label: 'Not In', value: 'NOT IN' },
];
export const FILTER_OPERATOR = new Map([
	['string', FILTER_OPERATOR_STR],
	['id', FILTER_OPERATOR_ID],
	['email', FILTER_OPERATOR_STR],
	['url', FILTER_OPERATOR_STR],
]);
export const FILTER_SUPPORTED_TYPES = ['email', 'url', 'string'];

// this is used as the placeholder name for sales play when there are none avaliable
export const TEMP_SALESPLAYBOOK = 'No Sales Play';
export const TEMP_INDUSTRY = 'None';
export const TEMP_ACCOUNT_OWNER = 'None';
export const TEMP_ACCOUNT_TYPE = 'None';
export const TEMP_ACCOUNT_RATING = 'No Rating';

export const TYPE_CATEGORY = 'category';
export const TYPE_SUBCATEGORY = 'subcategory';
export const TYPE_CATEGORY_DTLS = 'categoryDtls';
export const TYPE_SUBCATEGORY_DTLS = 'subcategoryDtls';
export const TYPE_QUESTION = 'question';
export const TYPE_ANSWER = 'answer';
export const TYPE_COMPLIANCE = 'compliance';
export const TYPE_REMOVE = 'remove';
export const SELECTION_SOURCE_AUTOSELECT = 'SELECTION_SOURCE_AUTOSELECT';
export const SELECTION_SOURCE_MANUAL = 'SELECTION_SOURCE_MANUAL';
export const SELECTION_SOURCE_AI = 'SELECTION_SOURCE_AI';
export const SELECTION_SOURCE_RECONFIGURE = 'SELECTION_SOURCE_RECONFIGURE';

export const ACCESS_ADMIN = 'admin';
export const ACCESS_USER = 'user';
export const ACCESS_NONE = 'noAccess';

export const SUMMARY_PREVIEW_SAMPLE_MSG =
	'This is only a sample summary for the selected account and there will be variation with the final summary.';

let configSalesPlayProduct = {};
//let versionIdInCondition = "("+ Array.from(salesplayIds).map((item)=> "'"+item+"'").join(',')+")";
configSalesPlayProduct.baseObjectApiName = NAMESPACE_PREFIX + 'SalesPlaybook__c';
//config.baseWhereCond =  `${NAMESPACE_PREFIX}ContentVersionId__c IN ${versionIdInCondition}`;
configSalesPlayProduct.junctionObjectName = `${NAMESPACE_PREFIX}SalesPlayProduct__c`;
configSalesPlayProduct.relationName = `${NAMESPACE_PREFIX}SalesPlayProducts__r`;
configSalesPlayProduct.productLookupRelName = `${NAMESPACE_PREFIX}TagId__r`;
configSalesPlayProduct.productLookupFieldName = `${NAMESPACE_PREFIX}TagId__c`;
configSalesPlayProduct.baseLookupRelName = `${NAMESPACE_PREFIX}SalesPlayId__r`;
configSalesPlayProduct.baseLookupFieldName = `${NAMESPACE_PREFIX}SalesPlayId__c`;

export const GEN_FLOW_OPPTY_OVERVIEW_PERSONA_INSIGHT = 'OpportunityOverviewPersonaInsight';
export const GEN_FLOW_LEAD_OVERVIEW_PERSONA_INSIGHT = 'LeadPersonaInsight';
export const GEN_FLOW_SUMMARIZE_SEARCH_RESULT = 'SummariseSearchResult';
export const GEN_FLOW_SUMMARIZE_File = 'SummariseFile';
export const GEN_AI_FLOW_10K_BUSINESS_PRODUCT_ENHANCEMENTS = 'BusinessProductEnhancements10k';
export const GEN_AI_FLOW_10K_BUSINESS_INDUSTRY_TRENDS = 'BusinessIndustryStandards10k';
export const GEN_AI_FLOW_10K_BUSINESS_GO_TO_MARKET = 'BusinessGoToMarket10k';
export const GEN_AI_FLOW_10K_RISKS_TECH_INNOVATION = 'RisksTechInvotions10k';
export const GEN_AI_FLOW_10K_RISKS_THREAT = 'RisksThreats10k';



export const OPPTY_OVERVIEW_ABOUT_COMPANY = 'OpportunityOverviewAboutCompany';
export const GEN_AI_SERVICE_OPENAI = 'openai';
export const GEN_AI_SERVICE_CLAUDE = 'claude';

let gptConfig = {};
gptConfig['SalesPlayCriticalMessaging'] = {};
gptConfig['SalesPlayCriticalMessaging']['DeveloperName'] = 'SalesPlayCriticalMessaging';
gptConfig['SalesPlayCriticalMessaging'][NAMESPACE_PREFIX + 'Fields__c'] = `[
    {
        "type" : "TextArea",
        "defaultValue" : "",
       "name": "moreInformation",
        "label" : "To enhance the critical messaging and positioning statement, you may enter data such as “the persona(s) you are selling to"
     }
]`;
gptConfig['SalesPlayCriticalMessaging'][NAMESPACE_PREFIX + 'Prompt__c'] = `[
	{
	   "role":"system",
	   "content":"You are {!companyName} Sales Assistant. You also have access to updated real-time news and information. You will be provided Company Name and About Company. You will be also provided Products company sell and industry of the company. You will be also provided the persona(s) {!companyName} are selling to optionally"
	},
	{
	   "role":"system",
	   "content":"{!command} ${SALESPLAY_SECTION_CRITICALMESSAGING} for the {!companyName}"
	},
	{
	   "role":"user",
	   "content":"Company Name: {!companyName}"
	},
	{
	   "role":"user",
	   "content":"About Company: {!companyAboutUs}"
	},
	{
	   "role":"user",
	   "content":"Products: {!ProductSelection}"
	},
	{
	   "role":"user",
	   "content":"Industry: {!fwave__Industry__c}"
	},
	{
	   "role":"user",
	   "content":"the persona(s) {!companyName} are selling to: {!moreInformation}"
	}
 ]`;

gptConfig['SalesPlayObjectionResponse'] = {};
gptConfig['SalesPlayObjectionResponse']['DeveloperName'] = 'SalesPlayObjectionResponse';
gptConfig['SalesPlayObjectionResponse'][NAMESPACE_PREFIX + 'Fields__c'] = `[
   
]`;
gptConfig['SalesPlayObjectionResponse'][NAMESPACE_PREFIX + 'Prompt__c'] = `[
	{
	   "role":"system",
	   "content":"You are {!companyName} Sales Assistant. You also have access to updated real-time news and information. You will be provided Company Name and About Company. You will be also provided Products company sell and industry of the company. You will be also provided the Objection for which you need to generate response optionally"
	},
	{
	   "role":"system",
	   "content":"{!command} Response of Objection for the {!companyName}"
	},
	{
	   "role":"user",
	   "content":"Company Name: {!companyName}"
	},
	{
	   "role":"user",
	   "content":"About Company: {!companyAboutUs}"
	},
	{
	   "role":"user",
	   "content":"Products: {!salesplay.ProductSelection}"
	},
	{
	   "role":"user",
	   "content":"Industry: {!salesplay.fwave__Industry__c}"
	},
	{
	   "role":"user",
	   "content":"Objection: {!fwave__Objection__c}"
	}
 ]`;

export const PERSONA_INSIGHT_CHATGPT_PROMPT = `You are an account executive at a company called '{!companyName}', which is working with company '{!accountName}' on Opportunity called '{!opportunityName}' with {!role}. Explain how {!companyName} company should approach to sell product '{!productName}'. Here is some more details about '{!productName}': {!productDescription}. Please make sure it should not be in email format and do not include placeholders.`;
export const PERSONA_INSIGHT_LEAD_CHATGPT_PROMPT = `You are an account executive at a company called '{!companyName}', which is working with company '{!accountName}' with {!role}. Explain how {!companyName} company should approach to sell product '{!productName}'. Here is some more details about '{!productName}': {!productDescription}. Please make sure it should not be in email format and do not include placeholders.`;
gptConfig['OpportunityOverviewPersonaInsight'] = {};
gptConfig['OpportunityOverviewPersonaInsight']['DeveloperName'] =
	'OpportunityOverviewPersonaInsight';
gptConfig['OpportunityOverviewPersonaInsight'][NAMESPACE_PREFIX + 'Prompt__c'] = `[
	{
		"role":"system",
		"content":""
	}, 
	{
		"role":"user",
		"content":"${PERSONA_INSIGHT_CHATGPT_PROMPT}"
	}
]`;

gptConfig['LeadPersonaInsight'] = {};
gptConfig['LeadPersonaInsight']['DeveloperName'] = 'LeadPersonaInsight';
gptConfig['LeadPersonaInsight'][NAMESPACE_PREFIX + 'Prompt__c'] = `[
	{
		"role":"system",
		"content":""
	}, 
	{
		"role":"user",
		"content":"${PERSONA_INSIGHT_CHATGPT_PROMPT}"
	}
]`;

gptConfig[GEN_FLOW_SUMMARIZE_SEARCH_RESULT] = {};
gptConfig[GEN_FLOW_SUMMARIZE_SEARCH_RESULT]['DeveloperName'] = GEN_FLOW_SUMMARIZE_SEARCH_RESULT;
gptConfig[GEN_FLOW_SUMMARIZE_SEARCH_RESULT][NAMESPACE_PREFIX + 'Prompt__c'] = `[
	{
		"role":"system",
		"content":""
	}, 
	{
		"role":"user",
		"content":"summarize this for me : {!content}"
	}
]`;

gptConfig[GEN_FLOW_SUMMARIZE_File] = {};
gptConfig[GEN_FLOW_SUMMARIZE_File]['DeveloperName'] = GEN_FLOW_SUMMARIZE_File;
gptConfig[GEN_FLOW_SUMMARIZE_File][NAMESPACE_PREFIX + 'Prompt__c'] = `[
	{
		"role":"system",
		"content":""
	}, 
	{
		"role":"user",
		"content":"summarize this for me : {!content}"
	}
]`;

gptConfig[OPPTY_OVERVIEW_ABOUT_COMPANY] = {};
gptConfig[OPPTY_OVERVIEW_ABOUT_COMPANY]['DeveloperName'] = OPPTY_OVERVIEW_ABOUT_COMPANY;
gptConfig[OPPTY_OVERVIEW_ABOUT_COMPANY][NAMESPACE_PREFIX + 'Prompt__c'] = `[
	{
		"role":"system",
		"content":""
	}, 
	{
		"role":"user",
		"content":"You are an account executive at a company. Give me short summary what company {!companyName} does. {!aboutCompany}"
	}
]`;

gptConfig[GEN_AI_FLOW_10K_BUSINESS_PRODUCT_ENHANCEMENTS] = {};
gptConfig[GEN_AI_FLOW_10K_BUSINESS_PRODUCT_ENHANCEMENTS]['DeveloperName'] = GEN_AI_FLOW_10K_BUSINESS_PRODUCT_ENHANCEMENTS;
gptConfig[GEN_AI_FLOW_10K_BUSINESS_PRODUCT_ENHANCEMENTS][NAMESPACE_PREFIX + 'Prompt__c'] = `[
	{
		"role":"system",
		"content":""
	}, 
	{
		"role":"user",
		"content":"You are an account executive at a company. Please identify product enhancements to the {!accountName} that {!accountName} feels will impact its position in the market. consider below business information : {!10kSummary}"
	}
]`;

gptConfig[GEN_AI_FLOW_10K_BUSINESS_INDUSTRY_TRENDS] = {};
gptConfig[GEN_AI_FLOW_10K_BUSINESS_INDUSTRY_TRENDS]['DeveloperName'] = GEN_AI_FLOW_10K_BUSINESS_INDUSTRY_TRENDS;
gptConfig[GEN_AI_FLOW_10K_BUSINESS_INDUSTRY_TRENDS][NAMESPACE_PREFIX + 'Prompt__c'] = `[
	{
		"role":"system",
		"content":""
	}, 
	{
		"role":"user",
		"content":"You are an account executive at a company. Please identify industry trends to the {!accountName} that {!accountName} feels will impact their business, either positively or negatively. consider below business information : {!10kSummary}"
	}
]`;

gptConfig[GEN_AI_FLOW_10K_BUSINESS_GO_TO_MARKET] = {};
gptConfig[GEN_AI_FLOW_10K_BUSINESS_GO_TO_MARKET]['DeveloperName'] = GEN_AI_FLOW_10K_BUSINESS_GO_TO_MARKET;
gptConfig[GEN_AI_FLOW_10K_BUSINESS_GO_TO_MARKET][NAMESPACE_PREFIX + 'Prompt__c'] = `[
	{
		"role":"system",
		"content":""
	}, 
	{
		"role":"user",
		"content":"You are an account executive at a company. Please identify any go-to-market strategies that {!accountName} has mentioned they will focus on. consider below business information : {!10kSummary}"
	}
]`;

gptConfig[GEN_AI_FLOW_10K_RISKS_TECH_INNOVATION] = {};
gptConfig[GEN_AI_FLOW_10K_RISKS_TECH_INNOVATION]['DeveloperName'] = GEN_AI_FLOW_10K_RISKS_TECH_INNOVATION;
gptConfig[GEN_AI_FLOW_10K_RISKS_TECH_INNOVATION][NAMESPACE_PREFIX + 'Prompt__c'] = `[
	{
		"role":"system",
		"content":""
	}, 
	{
		"role":"user",
		"content":"You are an account executive at a company. What technology innovations has {!accountName} Workday identified and what creates the most significant risk to the innovation? consider below business information : {!10kSummary}"
	}
]`;

gptConfig[GEN_AI_FLOW_10K_RISKS_THREAT] = {};
gptConfig[GEN_AI_FLOW_10K_RISKS_THREAT]['DeveloperName'] = GEN_AI_FLOW_10K_RISKS_THREAT;
gptConfig[GEN_AI_FLOW_10K_RISKS_THREAT][NAMESPACE_PREFIX + 'Prompt__c'] = `[
	{
		"role":"system",
		"content":""
	}, 
	{
		"role":"user",
		"content":"You are an account executive at a company. What are the most significant threats to {!accountName}’s ability to grow? consider below business information : {!10kSummary}"
	}
]`;

export { gptConfig as CONFIG_GPT_PROMPTS };
export { configSalesPlayProduct as CONFIG_SALESPLAY_PRODUCT };

export const SALESPLAY_SUMMARY_DEFAULT_GPT_PROMPT = `I am about to get on a sales call and only have 5 mins to prepare. Summarize the attached Sales Play for me and highlight the key points.

Sales Play Name: "{!salesplay.Name}".

Critical Messaging: "{!salesplay.fwave__CriticalMessaging__c}".

{!salesplay.fwave__SalesPlaybookDiscoveryIndustryTrends__r}

{!salesplay.fwave__Objection_Handlings__r}

{!salesplay.fwave__Sales_Playbook_Competitions__r}`;

export const FILES_SUPPORTED_DOCTYPES = ['.docx', '.pdf', '.ppt', '.pptx', '.xlsx', '.html'];
