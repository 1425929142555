import { NAMESPACE_PREFIX, ACCESS_ADMIN, RFP_ONLY_MAP } from '../Constants';
import Utils from '../utils/Utils';

var isLocalhost = Utils.isLocalhost() || false;
var sessionId = undefined;
var salesforceEndPoint = undefined;
var client = undefined;
var API_VERSION = 'v59.0';
var sfOrgId = undefined;
var params = undefined;
var environment = undefined;
var access = ACCESS_ADMIN;
var mappingConfig = undefined;
var currentUserId = undefined;
var enableCategory = false;
var enableSalesPlay = true;
var enableRequirements = true;
var enableNavigation = false;
var installedVersion;
var isScratchOrg = false;
var defaultBaseUrl = 'https://door.fwapps.com/v1/';
var labels = {};
if (isLocalhost) {
	currentUserId = process.env.REACT_APP_SF_USER_ID;
	defaultBaseUrl = 'https://door.fwapps.com/dev/v1/';
	environment = 'https://door.fwapps.com/dev/';
	sessionId = process.env.REACT_APP_SF_SESSION_ID;
	salesforceEndPoint = process.env.REACT_APP_SF_ENDPOINT;
	sfOrgId = process.env.REACT_APP_SF_ORG_ID;
	console.log('--- Creds --- ', process.env);
	access = ACCESS_ADMIN;
	mappingConfig =
		'{"ExpectedRevenue__c":"ExpectedRevenue","CloseDate__c":"CloseDate","OpportunityMapping__c":"Opportunity"}';
	enableSalesPlay = true;
	enableRequirements = true;
	installedVersion = '';
	isScratchOrg = true;
}

const SalesforceService = {
	// console.log('Getting Session Id : ' + sessionId)
	//if(typeof LCC !== 'undefined'  && LCC.getRESTAPISessionKey() !== '' )return LCC.getRESTAPISessionKey();
	getSessionId: () => sessionId,

	// console.log('Getting InstanceUrl : ' + salesforceEndPoint)
	//if(typeof LCC !== 'undefined'  && LCC.getRESTAPISessionKey() !== '' )return LCC.getRESTAPISessionKey();
	getInstanceUrl: () => salesforceEndPoint,

	getVersionDataUrl: (versionId) =>
		`${salesforceEndPoint}/services/data/${API_VERSION}/sobjects/ConetentVersion/${versionId}/VersionData`,
	getApiVersion: () => API_VERSION,
	getCanvasClient: () => client,
	getSfOrgId: () => sfOrgId,
	getParams: () => params,
	getEnvironment: () => environment || '',
	getAccess: () => access || '',
	isUser:()=>access === 'user',
	getMappingConfig: () => (mappingConfig ? JSON.parse(mappingConfig) : null),
	isCategoryEnabled: () => enableCategory === true,
	isSalesPlayEnabled: () => (typeof enableSalesPlay === 'boolean' ? enableSalesPlay : true),
	isNavigationEnabled: () => (typeof enableNavigation === 'boolean' ? enableNavigation : false),
	setSalesPlayEnabled: (newVal) => (enableSalesPlay = newVal),
	setNavigationEnabled: (newVal) =>
		(enableNavigation = newVal && newVal === true ? 'Redirect' : 'Stay'),
	isRequirementsEnabled: () =>
		typeof enableRequirements === 'boolean' ? enableRequirements : true,
	setRequirementsEnabled: (newVal) => (enableRequirements = newVal),
	getInstalledVersion: () => installedVersion || '',
	isScratchOrgFn: () => (typeof isScratchOrg === 'boolean' ? isScratchOrg : false),
	isRfpOnly: (opptyId) => {
		let tempRfpOnlyMap = localStorage.getItem(RFP_ONLY_MAP);
		if (tempRfpOnlyMap) {
			tempRfpOnlyMap = new Map(JSON.parse(tempRfpOnlyMap));
			return tempRfpOnlyMap.get(opptyId) || false;
		}
		return false;
	},
	checkMinimumVerion: ({ major, minor }) => {
		//Considering Scratch org will have latest and greatest feature so for that it will always return true
		try {
			if (isScratchOrg === true) return true;
			if (installedVersion && installedVersion != '') {
				if (!major) major = 0;
				let versionArr = installedVersion.split('.');
				let majorStr = versionArr[0];
				let minorStr = versionArr[1];
				if (majorStr && minorStr) {
					let majorInstalled = parseInt(majorStr);
					let minorInstalled = parseInt(minorStr);

					return majorInstalled === major
						? minorInstalled >= minor
						: majorInstalled > major
							? true
							: false;
				}
				return false;
			} else return false;
		} catch (e) {
			console.error('Error checking minimum package version. ', e);
			return false;
		}
	},
	getCurrentUserId: () => currentUserId || 'noUserId',
	getResourceUrl: () => environment || defaultBaseUrl,
	getApiUrl: () => (environment ? `${environment}v1/` : defaultBaseUrl),
	setSalesforceInfo: (salesforceInfo) => {
		sessionId = salesforceInfo.sessionId;
		salesforceEndPoint = salesforceInfo.salesforceEndPoint;
		client = salesforceInfo.client;
		sfOrgId = salesforceInfo.organizationId;
		params = salesforceInfo.params;
		environment = window.env || params?.env;
		environment = String(environment || '').endsWith('/') ? environment : `${environment}/`;
		access = params?.access;
		mappingConfig = params?.mappingConfig;
		currentUserId =
			params?.currentUserId ||
			(params?.recordId?.startsWith?.('005') ? params?.recordId : 'noUserId');
		enableCategory = params?.enableCategory;
		enableSalesPlay = params?.enableSalesPlay;
		enableRequirements =
			params?.enableRequirements === undefined ? true : params?.enableRequirements;
		installedVersion = params?.installedVersion;
		isScratchOrg = params?.isScratchOrg;
	},
	convertDataModel: (sfdcData) => {
		const featureWaveData = {};
		for (const property in sfdcData) {
			if (NAMESPACE_PREFIX !== '' && property.startsWith(NAMESPACE_PREFIX)) {
				const newProperty = property.substring(NAMESPACE_PREFIX.length);
				featureWaveData[newProperty] = sfdcData[newProperty];
			} else {
				featureWaveData[property] = sfdcData[property];
			}
		}
		// console.log('end obj', featureWaveData);
		return featureWaveData;
	},
	getLogoUrl: (documentId) => {
		let fileInstanceUrl = salesforceEndPoint.replace('my.salesforce.com', 'file.force.com');
		return `${fileInstanceUrl}/servlet/servlet.ImageServer?id=${documentId}&oid=${sfOrgId}`;
	},
	setLabels: (_labels) =>
		(labels = _labels || {}),
	getLabels: ()=>{return labels;}
};

// SalesforceService.invokeApex = ({method='', parameters = {}})=> {
//     const requestOptions = {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json', 'Authorization' : 'Bearer '+sessionId },
//         body: JSON.stringify({ method: method})
//     };

//     fetch(salesforceEndPoint + '/services/apexrest/globalapi',requestOptions).then(response => console.log({response : response.json()}))

// }

// SalesforceService.getAccessToken = ()=>{
//     SalesforceService.invokeApex({method : 'getToken'});
// }

export default SalesforceService;

export const getSfContentVersionUrl = () =>
	`${SalesforceService.getInstanceUrl()}/services/data/v59.0/sobjects/ContentVersion`;

export const getCompanionUploadEndpoint = () =>
	`${SalesforceService.getApiUrl()}sf/UploadRemoteFile`;

export const getCompanionUrl = () => 'https://file.fwapps.com/companion';
// export const getCompanionUrl = () => 'http://localhost:3020/companion';
